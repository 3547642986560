import _ from "lodash";
import { requestAPI } from "src/apis/requestApi";
import { PaginationResponse, Pageable } from "src/apis/interface";
import {
  CreateWalletCreationRequest,
  ClientRequestControllerApiFactory,
  ClientRequestType,
  PaginationClientRequestBoxDto,
  ClientRequestBoxDto,
  ClientRequestStatus,
  Action,
  WalletStatus,
  AddressBookClientRequestDto,
  TransferClientRequestDto,
  WalletClientRequestDto,
  CustodyRequestStatus,
  TransferStatus,
  SubmittedClientRequestType,
  CreateAddressBookCreationRequest,
  CreateTransferRequest,
  CreateAddressBookDeletionRequest,
} from "src/__generate__/api";
import { AxiosPromise } from "axios";

const clientRequestControllerApiFactory = () =>
  ClientRequestControllerApiFactory(undefined, "", requestAPI());

const prefixUrl = "/api/v1/client/requests";

export type RequestTransferType = "transfer" | "address_book" | "wallet";

export const inbox = async (params: {
  request: {
    walletId?: string;
    status?: ClientRequestStatus;
    type?: ClientRequestType;
  };
  pageable: Pageable;
}): Promise<PaginationClientRequestBoxDto> => {
  const { request, pageable } = params;
  const { walletId, status, type } = request;
  const { page, size } = pageable;

  const response = await clientRequestControllerApiFactory().getInboxRequests(
    walletId,
    status,
    type,
    page,
    size,
  );
  return response.data;
};

export const outbox = async (params: {
  request: {
    walletId?: string;
    status?: ClientRequestStatus;
    type?: ClientRequestType;
  };
  pageable: Pageable;
}): Promise<PaginationClientRequestBoxDto> => {
  const { request, pageable } = params;
  const { walletId, status, type } = request;
  const { page, size } = pageable;

  const response = await clientRequestControllerApiFactory().getOutboxRequests(
    walletId,
    status,
    type,
    page,
    size,
  );
  return response.data;
};

export const requests = async (params: {
  request: {
    walletId?: string;
    type?: ClientRequestType;
    custodyRequestStatuses: CustodyRequestStatus[];
    transferStatuses: TransferStatus[];
    walletStatuses: WalletStatus[];
  };
  pageable: Pageable;
}): Promise<PaginationResponse<ClientRequestBoxDto>> => {
  const { request, pageable } = params;
  const {
    walletId,
    type,
    custodyRequestStatuses,
    transferStatuses,
    walletStatuses,
  } = request;
  const { page, size } = pageable;
  const transformSubmittedClientRequestType = (type?: ClientRequestType) => {
    if (!type) {
      return;
    }
    const byType: Record<
      ClientRequestType,
      SubmittedClientRequestType | undefined
    > = {
      [ClientRequestType.Transfer]: SubmittedClientRequestType.Transfer,
      [ClientRequestType.Wallet]: SubmittedClientRequestType.Wallet,
      [ClientRequestType.AddressBook]: undefined,
    };
    return byType[type];
  };

  const response =
    await clientRequestControllerApiFactory().getSubmittedRequests(
      walletId,
      transformSubmittedClientRequestType(type),
      custodyRequestStatuses,
      transferStatuses,
      walletStatuses,
      page,
      size,
    );
  return response.data;
};

export const requestTransferById = async (params: {
  requestId: string;
}): Promise<TransferClientRequestDto> => {
  const { requestId } = params;
  const response =
    await clientRequestControllerApiFactory().getTransferRequest1(requestId);
  return response.data;
};

export const requestAddressBookById = async (params: {
  requestId: string;
}): Promise<AddressBookClientRequestDto> => {
  const { requestId } = params;
  const response =
    await clientRequestControllerApiFactory().getAddressBookRequest1(requestId);
  return response.data;
};

export const requestWalletById = async (params: {
  requestId: string;
}): Promise<WalletClientRequestDto> => {
  const { requestId } = params;
  const response =
    await clientRequestControllerApiFactory().getWalletCreationRequest1(
      requestId,
    );
  return response.data;
};

export const requestWithdraw = async (
  params: CreateTransferRequest,
): Promise<TransferClientRequestDto> => {
  const response =
    await clientRequestControllerApiFactory().createTransfer1(params);
  return response.data;
};

export const requestAddressBook = async (
  params: CreateAddressBookCreationRequest,
): Promise<AddressBookClientRequestDto> => {
  const response =
    await clientRequestControllerApiFactory().createAddressBookCreation1(
      params,
    );
  return response.data;
};

export const requestWallet = async (
  params: CreateWalletCreationRequest,
): Promise<WalletClientRequestDto> => {
  const response =
    await clientRequestControllerApiFactory().createWalletCreation1(params);
  return response.data;
};

export const requestDeleteAddressBook = async (
  params: CreateAddressBookDeletionRequest,
): Promise<AddressBookClientRequestDto> => {
  const response =
    await clientRequestControllerApiFactory().createAddressBookDeletion1(
      params,
    );
  return response.data;
};

export const deleteAddressBookRequestById = async (request: {
  action: Action;
  requestId: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { action, requestId, ...rest } = request;
  const byAction: Record<Action, () => AxiosPromise<void>> = {
    [Action.Create]: () =>
      clientRequestControllerApiFactory().deleteAddressBookCreateRequest1(
        requestId,
        rest,
      ),
    [Action.Delete]: () =>
      clientRequestControllerApiFactory().deleteAddressBookDeleteRequest1(
        requestId,
        rest,
      ),
  };
  await byAction[action]();
};

export const deleteTransferRequestById = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { requestId, ...rest } = request;

  await clientRequestControllerApiFactory().deleteTransferRequest1(
    requestId,
    rest,
  );
};

export const approveAddressBookRequestById = async (request: {
  action: Action;
  requestId: string;
  memo?: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { action, requestId, ...rest } = request;
  const byAction: Record<Action, () => AxiosPromise<void>> = {
    [Action.Create]: () =>
      clientRequestControllerApiFactory().approveAddressBookCreateRequest1(
        requestId,
        rest,
      ),
    [Action.Delete]: () =>
      clientRequestControllerApiFactory().approveAddressBookDeleteRequest1(
        requestId,
        rest,
      ),
  };
  await byAction[action]();
};

export const approveTransferRequestById = async (request: {
  requestId: string;
  memo?: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { requestId, ...rest } = request;
  await clientRequestControllerApiFactory().approveTransferRequest1(
    requestId,
    rest,
  );
};

export const rejectAddressBookRequestById = async (request: {
  action: Action;
  requestId: string;
  memo?: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { action, requestId, ...rest } = request;
  const byAction: Record<Action, () => AxiosPromise<void>> = {
    [Action.Create]: () =>
      clientRequestControllerApiFactory().rejectAddressBookCreateRequest1(
        requestId,
        rest,
      ),
    [Action.Delete]: () =>
      clientRequestControllerApiFactory().rejectAddressBookDeleteRequest1(
        requestId,
        rest,
      ),
  };
  await byAction[action]();
};

export const rejectTransferRequestById = async (request: {
  requestId: string;
  memo?: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { requestId, ...rest } = request;
  await clientRequestControllerApiFactory().rejectTransferRequest1(
    requestId,
    rest,
  );
};

export const cancelWalletRequestById = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { requestId, ...rest } = request;
  await clientRequestControllerApiFactory().cancelWalletRequest1(
    requestId,
    rest,
  );
};

export const cancelTransferRequestById = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}) => {
  const { requestId, ...rest } = request;
  await clientRequestControllerApiFactory().cancelTransferRequest1(
    requestId,
    rest,
  );
};

export const getVaspList = async () => {
  const response = await clientRequestControllerApiFactory().getVaspList();
  return response.data;
};
