import _ from "lodash";

export const testHangul = (test: string) => {
  return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(test);
};

export const testWalletName = (test: string) => {
  return /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣A-Za-z0-9-_.+]*$/g.test(test);
};

// reference https://stackoverflow.com/questions/4460586/javascript-regular-expression-to-check-for-ip-addresses
export const testIpAddress = (test: string) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    test,
  );
};

export const testAddress = (test: string) => {
  return /^[A-Za-z0-9]*$/g.test(test);
};

export const testOTP = (test: string) => {
  return /^[0-9]{0,6}$/g.test(test);
};

export const testEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const testUsername = (name: string) => {
  const nameValidationRegex = /[[\]\-/\\=,.;'"`~!@#$%^&*()_+|<>?:{}|0-9]/;
  return !nameValidationRegex.test(name);
};

export const extractEmails = (message: string) => {
  const emailMessages = /\[(.*?)\]/g.exec(message);
  const firstEmailMessage = emailMessages?.[0] ?? "";
  return firstEmailMessage.substring(1, firstEmailMessage.length - 1);
};

export type PasswordRule = {
  hasAtLeast8Char: boolean;
  hasAlphabet: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
};

export const testPassword = (password: string): PasswordRule => {
  const testAtLeast8Char = /.{8,}/;
  const testCapitalLetter = /[A-Z]+/;
  const testSmallLetter = /[a-z]+/;
  const testNumber = /[0-9]+/;
  const testSpecialChar = /[^\w\\s_]+/;

  return {
    hasAtLeast8Char: testAtLeast8Char.test(password),
    hasAlphabet:
      testCapitalLetter.test(password) || testSmallLetter.test(password),
    hasNumber: testNumber.test(password),
    hasSpecialChar: testSpecialChar.test(password),
  };
};

export const isInvalidPassword = (password: string) => {
  return _.values(testPassword(password)).some((hasRule) => !Boolean(hasRule));
};
