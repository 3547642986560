import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import { TransferAddressUserItem } from "src/interfaces/wallet";
import {
  requestTransfers,
  requestTransferById,
} from "src/apis/v2/custody/requests";
import {
  OrganizationDto,
  ClientApprovalDto,
  AuthorityName,
  WithdrawalCustodyRequestV2Dto,
  CustodyRequestV2Status,
  SigningV2Dto,
} from "src/__generate__/api";
import {
  IntegrationRequestStatus,
  transformIntegrationRequestV2Status,
} from "src/interfaces/request";
import { filterEmpty } from "src/utils/array";

export type RequestWithdrawalV2Item = WithdrawalCustodyRequestV2Dto & {
  wallet: TransferAddressUserItem;
  to: TransferAddressUserItem;
  integrationRequestStatus: IntegrationRequestStatus;
  organization?: OrganizationDto;
  commitSigning?: SigningV2Dto;
  confirmSigning?: SigningV2Dto;
  midApprovers?: ClientApprovalDto[];
  finalApprover?: ClientApprovalDto;
  estimatedFee?: string;
};

type Variables = {
  search?: string;
  statuses: CustodyRequestV2Status[];
};

const DEFAULT_PAGE_SIZE = 15;
const INITIALIZE_VARIABLES: Variables = {
  search: undefined,
  statuses: [],
};

const RequestWithdrawalsV2 = types
  .model("RequestWithdrawalsV2", {
    data: types.optional(
      types.map(types.frozen<RequestWithdrawalV2Item>()),
      {},
    ),
    dataArray: types.optional(types.array(types.string), []),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
    variables: types.frozen<Variables>(INITIALIZE_VARIABLES),
    hasMore: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get requestWithdrawalViews() {
        return _.orderBy(
          filterEmpty(self.dataArray.map((id) => self.data.get(id))).filter(
            (item) =>
              self.variables.statuses.some((status) => status === item.status),
          ),
          ["createdAt"],
          ["desc"],
        );
      },
      get searchText() {
        return self.variables.search ?? "";
      },
      get isSearch() {
        if (!this.searchText) {
          return false;
        }
        return true;
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
      self.hasMore = true;
      self.variables = INITIALIZE_VARIABLES;
      self.data.clear();
    };

    const fetchRequestWithdrawalById = flow(function* (requestId: string) {
      const response: RetrieveAsyncFunc<typeof requestTransferById> =
        yield requestTransferById({
          requestId,
        });
      yield upsertRequestWithdrawal(response);
      return self.data.get(response.id)!;
    });

    const fetch = flow(function* () {
      const { statuses, search } = self.variables;
      const response: RetrieveAsyncFunc<typeof requestTransfers> =
        yield requestTransfers({
          statuses,
          search,
          pageable: {
            page: self.page,
            size: self.size,
          },
        });
      const dataArray = [];
      for (const item of response.results) {
        upsertRequestWithdrawal(item);
        dataArray.push(item.id);
      }
      self.dataArray.replace(dataArray);
      self.totalCount = response.pagination.totalCount;
      self.hasMore = response.results.length !== 0;
    });

    const upsertRequestWithdrawal = async (
      item: WithdrawalCustodyRequestV2Dto,
    ) => {
      const approvals: ClientApprovalDto[] = item?.approvals ?? [];
      const { status: custodyRequestStatus } = item;
      const { wallet, to, status: transferStatus } = item.withdrawal;

      const midApprovers = approvals.filter(
        (approval) => approval.authority === AuthorityName.CustodyManager,
      );
      const finalApprover = approvals.find(
        (approval) => approval.authority === AuthorityName.CustodyAdmin,
      );

      self.data.set(item.id, {
        ...item,
        wallet: {
          userType: "master_wallet",
          address: wallet.address ?? "",
          masterWallet: {
            id: wallet.id,
            name: wallet.name,
          },
        },
        to: {
          userType: "address",
          address: to,
        },
        integrationRequestStatus: transformIntegrationRequestV2Status({
          transferStatus,
          custodyRequestStatus,
        }),
        midApprovers,
        finalApprover,
      });
    };

    const setPage = flow(function* ({
      page,
      size,
    }: {
      page: number;
      size?: number;
    }) {
      self.page = page;
      self.size = size ?? self.size;
      yield fetch();
    });

    const initialize = flow(function* (options: Partial<Variables>) {
      clear();
      const existVariables = self.variables;
      self.variables = {
        ...existVariables,
        ...options,
      };
      yield fetch();
    });

    const search = flow(function* (params: { search?: string }) {
      const newVariables = {
        ...self.variables,
        search: params.search,
      };
      clear();
      self.variables = newVariables;
      yield fetch();
    });

    return {
      clear,
      initialize,
      setPage,
      search,
      refresh: fetch,
      fetchRequestWithdrawalById,
      upsertRequestWithdrawal,
    };
  });

export type IRequestWithdrawals = Instance<typeof RequestWithdrawalsV2>;
export default RequestWithdrawalsV2;
