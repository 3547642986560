import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import { getMasterWalletById, getMasterWallets } from "src/apis/wallet";

import {
  getMasterWalletById as getMasterWalletByIdV2,
  getMasterWallets as getMasterWalletsV2,
} from "src/apis/v2/wallet";
import MasterWallet from "src/stores/model/MasterWallet";
import { orderByBlockchain } from "src/utils/array";
import {
  WalletWithRequestDto,
  Blockchain,
  WalletStatus,
  WalletV2WithRequestDto,
} from "src/__generate__/api";
import { getRootStore } from "./StoreHelper";

export type DrawerWalletItem = {
  id: string;
  name: string;
  blockchain: Blockchain;
  walletStatus: string;
};

const WalletStore = types
  .model("WalletStore", {
    masterWallets: types.optional(
      types.map(types.late(() => MasterWallet)),
      {},
    ),
  })
  .views((self) => {
    return {
      get firstMasterWallet() {
        const views = Array.from(self.masterWallets.values());
        if (views.length > 0) {
          return _.first(views);
        }
        return null;
      },
      get hasMasterWallet() {
        return Boolean(self.masterWallets.size);
      },
      get masterWalletViews() {
        return Array.from(self.masterWallets.values());
      },
      get masterWalletSelectboxViews() {
        return [
          {
            id: "",
            blockchain: null,
            name: "모든 지갑",
            address: "",
          },
          ...this.masterWalletViews.map((item) => {
            const { id, name, blockchain, myAddress } = item;
            return {
              id,
              blockchain,
              name,
              address: myAddress,
            };
          }),
        ];
      },
      get drawerWalletViews() {
        const result: DrawerWalletItem[] = Array.from(
          self.masterWallets.values(),
        )
          .map((item) => {
            const { id, name, blockchain, walletStatus } = item;
            return {
              id,
              name,
              blockchain,
              walletStatus,
            };
          })
          .filter(
            (item) =>
              ![WalletStatus.Deleted].some(
                (status) => status === item.walletStatus,
              ),
          );
        const sortABC = (list: DrawerWalletItem[]) =>
          _.orderBy(list, [
            function (item) {
              return item.name.toUpperCase();
            },
          ]);
        return orderByBlockchain(result, sortABC);
      },
      existsMasterWalletById(id: string) {
        return self.masterWallets.has(id);
      },
      existsMasterWalletByName(name: string) {
        const masterWallets = Array.from(self.masterWallets.values());
        return masterWallets.some((item) => item.name === name);
      },
      getMasterWalletByName(name: string) {
        const masterWallets = Array.from(self.masterWallets.values());
        return masterWallets.find((item) => item.name === name);
      },
      masterWalletById(id: string) {
        return self.masterWallets.get(id);
      },
      masterWalletByAddress(address: string) {
        const masterWallets = Array.from(self.masterWallets.values());
        return masterWallets.find((item) => item.myAddress === address);
      },
      masterWalletByBlockchain(blockchain: Blockchain) {
        const masterWallets = Array.from(self.masterWallets.values());
        return masterWallets.filter((item) => item.blockchain === blockchain);
      },
    };
  })
  .actions((self) => {
    const upsertMasterWallet = (
      masterWallet: WalletWithRequestDto | WalletV2WithRequestDto,
    ) => {
      const {
        id,
        createdAt,
        blockchain,
        name = "",
        status,
        approvalPolicy,
        initialized,
      } = masterWallet;
      const existsMasterWallet = self.masterWalletById(id);
      if (existsMasterWallet) {
        existsMasterWallet.update({
          name,
          status,
          approvalPolicy: existsMasterWallet.approvalPolicy,
          balances: existsMasterWallet.balances.balanceViews,
          initialized: initialized ?? false,
          masterWallet,
        });
        return existsMasterWallet;
      }
      const masterWalletModel = MasterWallet.create({
        id,
        createdAt,
        name,
        blockchain,
        status,
        approvalPolicy,
        balances: {},
        initialized: initialized ?? false,
        masterWallet,
      });
      self.masterWallets.put(masterWalletModel);
      return masterWalletModel;
    };

    const fetchMasterWallets = flow(function* () {
      const [response, responseV2] = yield Promise.all([
        getMasterWallets(),
        getMasterWalletsV2(),
      ]);

      for (const wallet of response) {
        upsertMasterWallet(wallet);
      }
      for (const wallet of responseV2) {
        upsertMasterWallet(wallet);
      }
    });

    const fetchMasterWalletById = flow(function* ({
      masterWalletId,
      blockchain,
    }: {
      masterWalletId: string;
      blockchain: Blockchain;
    }) {
      let getById;
      if (getRootStore(self).blockchains?.checkV2Chain(blockchain)) {
        getById = getMasterWalletByIdV2;
      } else {
        getById = getMasterWalletById;
      }
      const response: RetrieveAsyncFunc<typeof getById> = yield getById({
        id: masterWalletId,
      });
      upsertMasterWallet(response);
    });

    return {
      fetchMasterWallets,
      fetchMasterWalletById,
    };
  });

export type IWalletStore = Instance<typeof WalletStore>;
export default WalletStore;
