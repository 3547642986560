import {
  AdminOrganizationControllerApiFactory,
  AuthorityDto,
  AuthorityName,
  CreateOrganizationInviteRequest,
  DetailOrganizationInviteProjection,
  GetOrganizationDetailInfoResponse,
  GetOrganizationCreationTargetResponse,
  OrganizationAccountDto,
  OrganizationControllerApiFactory,
  OrganizationInvitationControllerApiFactory,
  OtpOnlyAuthenticateRequest,
  PaginationGetOrganizationInfoResponse,
  PaginationOrganizationDto,
  PaginationSimpleOrganizationInvitationProjection,
  ProcessOrganizationInviteRequest,
  PublicOrganizationInvitationInfoProjection,
  SimpleOrganizationInvitationDTO,
  VerifyOrganizationInviteRequest,
} from "src/__generate__/api";
import { requestAPI } from "src/apis/requestApi";
import { Pageable } from "./interface";
import { ErrorCode } from "src/libs/error";

const organizationControllerApi = () =>
  OrganizationControllerApiFactory(undefined, "", requestAPI());

const organizationInvitationControllerApi = () =>
  OrganizationInvitationControllerApiFactory(undefined, "", requestAPI());

const adminOrganizationControllerApi = () =>
  AdminOrganizationControllerApiFactory(undefined, "", requestAPI());

export const getOrganizations = async (params: {
  request: { organizationName?: string };
  pageable: Pageable;
}): Promise<PaginationOrganizationDto> => {
  const { request, pageable } = params;
  const { organizationName } = request;
  const { page, size } = pageable;
  const response = await adminOrganizationControllerApi().getOrganizations(
    page,
    size,
    undefined,
    organizationName,
  );
  return response.data;
};

export const getOrganizations1 = async (params: {
  request: { orgId?: string };
  pageable: Pageable;
}): Promise<PaginationGetOrganizationInfoResponse> => {
  const { request, pageable } = params;
  const { orgId } = request;
  const { page, size } = pageable;
  const response = await adminOrganizationControllerApi().getOrganizations1(
    orgId,
    page,
    size,
  );

  return response.data;
};

export const getOrganizationDetailInfo = async (params: {
  request: { orgId: string };
}): Promise<GetOrganizationDetailInfoResponse> => {
  const {
    request: { orgId },
  } = params;
  const response =
    await adminOrganizationControllerApi().getOrganizationDetailInfo(orgId);
  return response.data;
};

export const verifyTaxIdentificationNumber = async (
  taxIdentificationNumber: string,
) => {
  try {
    await adminOrganizationControllerApi().verifyTaxIdentificationNumber({
      taxIdentificationNumber,
    });
    return true;
  } catch (error) {
    return false;
    // if (error.status === ErrorCode.DUPLICATED_ORGANIZATION_NAME) {
    //   return false;
    // }
    // throw error;
  }
};

export const verifyCorporationNumber = async (corporationNumber: string) => {
  try {
    await adminOrganizationControllerApi().verifyCorporationNumber({
      corporationNumber,
    });
    return true;
  } catch (error) {
    return false;
    // throw error;
  }
};

export const organizationMe = async () => {
  const response = await organizationControllerApi().getMe();

  return response.data;
};

export const organizationInitialized = async (orgId: string) => {
  await organizationControllerApi().initialize(orgId);
};

export const verifyOrganizationName = async (name: string) => {
  try {
    await organizationInvitationControllerApi().verifyOrganizationName1({
      name,
    });
    return true;
  } catch (error: any) {
    if (error.status === ErrorCode.DUPLICATED_ORGANIZATION_NAME) {
      return false;
    }
    throw error;
  }
};

export const verifyOrganizationEmail = async (email: string) => {
  try {
    await organizationInvitationControllerApi().verifySuperAmdinEmail({
      email,
    });
    return true;
  } catch (error: any) {
    if (error.status === ErrorCode.DUPLICATED_EMAIL) {
      return false;
    }
    throw error;
  }
};

export const updateOrganizationName = async (request: {
  orgId: string;
  name: string;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { orgId, ...rest } = request;
  await organizationControllerApi().changeOrganizationName(orgId, rest);
};

export const inviteMembers = async (request: {
  orgId: string;
  emails: string[];
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { orgId, ...rest } = request;
  await organizationControllerApi().inviteAccount(orgId, rest);
};

export const reInviteMember = async (request: {
  orgId: string;
  accountId: string;
}): Promise<void> => {
  const { orgId, accountId } = request;
  await organizationControllerApi().reInviteAccount(orgId, accountId);
};

export const cancelInviteMember = async (request: {
  orgId: string;
  accountId: string;
}): Promise<void> => {
  const { orgId, accountId } = request;
  await organizationControllerApi().cancelInviteAccount(orgId, accountId);
};

export const deleteInviteMember = async (request: {
  orgId: string;
  accountId: string;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { orgId, accountId, ...rest } = request;
  await organizationControllerApi().deleteOrganizationAccount(
    orgId,
    accountId,
    rest,
  );
};

export const updateAccountRoles = async (request: {
  orgId: string;
  accountId: string;
  deletedAuthorityNames: AuthorityName[];
  addedAuthorityNames: AuthorityName[];
  passphrase: string;
  otpCode: string;
}) => {
  const { orgId, accountId, ...rest } = request;
  await organizationControllerApi().changeAccountRoles(orgId, accountId, rest);
};

export const getRoles = async (orgId: string): Promise<AuthorityDto[]> => {
  const response =
    await organizationControllerApi().getOrganizationAuthorities(orgId);
  return response.data;
};

export const getAccounts = async (
  orgId: string,
): Promise<OrganizationAccountDto[]> => {
  const response =
    await organizationControllerApi().getAllOrganizationAccounts(orgId);
  return response.data;
};

export const getAccountById = async (request: {
  orgId: string;
  accountId: string;
}): Promise<OrganizationAccountDto> => {
  const { orgId, accountId } = request;
  const response = await organizationControllerApi().getOrganizationAccount(
    orgId,
    accountId,
  );
  return response.data;
};

export const handOverRoles = async (request: {
  orgId: string;
  accountId: string;
  to: string;
  authorityName: AuthorityName;
  passphrase: string;
  otpCode: string;
}) => {
  const { orgId, accountId, ...rest } = request;
  await organizationControllerApi().handOverRoles(orgId, accountId, {
    ...rest,
    fallback: AuthorityName.CustodyManager,
  });
};

export const verifyInvite = async (
  request: {
    inviteId: string;
  } & VerifyOrganizationInviteRequest,
): Promise<PublicOrganizationInvitationInfoProjection> => {
  const { inviteId, ...rest } = request;
  const response = await organizationInvitationControllerApi().verifyInvitation(
    inviteId,
    rest,
  );
  return response.data;
};

export const acceptInvite = async (
  request: {
    inviteId: string;
  } & ProcessOrganizationInviteRequest,
): Promise<void> => {
  const { inviteId, ...rest } = request;
  await organizationInvitationControllerApi().acceptInvitation(inviteId, rest);
};

export const createInvitation = async (
  request: CreateOrganizationInviteRequest,
): Promise<SimpleOrganizationInvitationDTO> => {
  const response =
    await organizationInvitationControllerApi().createInvitation(request);
  return response.data;
};

export const approveInvitation = async (
  request: { inviteId: string } & OtpOnlyAuthenticateRequest,
): Promise<void> => {
  const { inviteId, ...rest } = request;
  await organizationInvitationControllerApi().approveInvitation(inviteId, rest);
};

export const cancelInvitation = async (
  request: { inviteId: string } & OtpOnlyAuthenticateRequest,
): Promise<void> => {
  const { inviteId, ...rest } = request;
  await organizationInvitationControllerApi().cancelInvitation(inviteId, rest);
};

export const rejectInvitataion = async (
  request: { inviteId: string } & OtpOnlyAuthenticateRequest,
): Promise<void> => {
  const { inviteId, ...rest } = request;
  await organizationInvitationControllerApi().rejectInvitataion(inviteId, rest);
};

export const resendInvitation = async (
  request: { inviteId: string } & OtpOnlyAuthenticateRequest,
): Promise<void> => {
  const { inviteId, ...rest } = request;
  await organizationInvitationControllerApi().resendInvitation(inviteId, rest);
};

export const getOrganizationInvitations = async (params: {
  request: {
    organizationName?: string;
  };
  pageable: Pageable;
}): Promise<PaginationSimpleOrganizationInvitationProjection> => {
  const { request, pageable } = params;
  const { organizationName } = request;
  const { page, size } = pageable;

  const response =
    await organizationInvitationControllerApi().getOrganizationInvitations(
      page,
      size,
      undefined,
      organizationName,
    );
  return response.data;
};

export const getOrganizationInvitationDetail = async (
  inviteId: string,
): Promise<DetailOrganizationInviteProjection> => {
  const response =
    await organizationInvitationControllerApi().getOrganizationInvitationDetail(
      inviteId,
    );
  return response.data;
};

export const getListedCoins = async (orgId: string) => {
  const response = await organizationControllerApi().getListedCoins(orgId);
  return response.data;
};

export const deleteListedCoin = async (request: {
  orgId: string;
  listedCoinId: string;
  otpCode: string;
}) => {
  const { orgId, listedCoinId, otpCode } = request;
  const response = await organizationControllerApi().deleteListedCoin(
    orgId,
    listedCoinId,
    { otpCode },
  );
  return response.data;
};

export const addListedCoin = async (request: {
  orgId: string;
  coinId: string;
  otpCode: string;
}) => {
  const { orgId, coinId, otpCode } = request;
  const response = await organizationControllerApi().listCoin(orgId, {
    coinId,
    otpCode,
  });
  return response.data;
};

export const getOrganizationCreationTargets = async (): Promise<
  GetOrganizationCreationTargetResponse[]
> => {
  const response =
    await adminOrganizationControllerApi().getOrganizationCreationTargets();
  return response.data;
};
