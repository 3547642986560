import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import RequestWithdrawals from "src/stores/custody/RequestWithdrawals";
import RequestRegisterWallets from "src/stores/custody/RequestRegisterWallets";
import { requestTransfers, requestWallets } from "src/apis/custody/requests";
import {
  AuthorityName,
  CustodyNonSigningRequestStatus,
  OrganizationManagementCustodyRequestDto,
} from "src/__generate__";
import { getRootStore } from "./StoreHelper";
import { transformCustodyRequestStatusByAuthorityName } from "src/interfaces/custody";
import { getOrganizationUpgradeInfoByStatuses } from "src/apis/organization-management/organizationManagement";

const ALL_CUSTODY_UPGRADE_INFO_STATUSES = [
  CustodyNonSigningRequestStatus.PendingApproval,
  CustodyNonSigningRequestStatus.PendingFinalApproval,
];
const CustodyRequestStore = types
  .model("CustodyRequestStore", {
    requestWithdrawals: types.optional(
      types.late(() => RequestWithdrawals),
      {},
    ),
    requestRegisterWallets: types.optional(
      types.late(() => RequestRegisterWallets),
      {},
    ),
    pendingWithdrawalCount: types.optional(types.number, 0),
    pendingRegisterWalletCount: types.optional(types.number, 0),
    pendingRequestUpgradeCount: types.optional(types.number, 0),
  })
  .actions((self) => {
    const fetchPendingRequestUpgradeCount = flow(function* () {
      const { id, orgRolesByResourceExcludedMember } = getRootStore(self)
        .authStore?.user ?? {
        id: "",
        name: "",
        email: "",
      };
      const orgRoles = _.map(orgRolesByResourceExcludedMember, (item) => {
        return item.authority.name;
      });
      const isManager = orgRoles.includes(AuthorityName.CustodyManager);
      const isAdmin = orgRoles.includes(AuthorityName.CustodyAdmin);

      const all = yield getOrganizationUpgradeInfoByStatuses(
        ALL_CUSTODY_UPGRADE_INFO_STATUSES,
      );

      const withoutMyRequestCount = () => {
        if (isManager) {
          return all.results.filter(
            (item: OrganizationManagementCustodyRequestDto) =>
              item.status === CustodyNonSigningRequestStatus.PendingApproval &&
              item.requester.id !== id,
          ).length;
        }
        if (isAdmin) {
          return all.results.filter(
            (item: OrganizationManagementCustodyRequestDto) =>
              item.status ===
              CustodyNonSigningRequestStatus.PendingFinalApproval,
          ).length;
        }
      };
      self.pendingRequestUpgradeCount = withoutMyRequestCount();
    });

    const fetchPendingWithdrawalCount = flow(function* () {
      const custodyAuthorityName = _.first(
        getRootStore(self).authStore.user.custodyAuthorityNames,
      );
      const statuses = transformCustodyRequestStatusByAuthorityName(
        custodyAuthorityName?.authority?.name ?? AuthorityName.CustodyViewer,
      );
      if (!statuses) {
        return;
      }
      const response: RetrieveAsyncFunc<typeof requestTransfers> =
        yield requestTransfers({
          statuses,
          pageable: {
            page: 0,
            size: 1,
          },
        });
      self.pendingWithdrawalCount = response.pagination.totalCount;
    });

    const fetchPendingRegisterWalletCount = flow(function* () {
      const custodyAuthorityName = _.first(
        getRootStore(self).authStore.user.custodyAuthorityNames,
      );
      const statuses = transformCustodyRequestStatusByAuthorityName(
        custodyAuthorityName?.authority?.name ?? AuthorityName.CustodyViewer,
      );
      if (!statuses) {
        return;
      }
      const response: RetrieveAsyncFunc<typeof requestWallets> =
        yield requestWallets({
          statuses,
          pageable: {
            page: 0,
            size: 1,
          },
        });
      self.pendingRegisterWalletCount = response.pagination.totalCount;
    });

    return {
      fetchPendingWithdrawalCount,
      fetchPendingRegisterWalletCount,
      fetchPendingRequestUpgradeCount,
    };
  });

export type ICustodyRequestStore = Instance<typeof CustodyRequestStore>;
export default CustodyRequestStore;
