import { requestAPI } from "src/apis/requestApi";
import { CoinV2ControllerApiFactory } from "src/__generate__/api";

const coinV2ControllerApi = () =>
  CoinV2ControllerApiFactory(undefined, "", requestAPI());

export const getCoins = async () => {
  const response = await coinV2ControllerApi().getCoins();
  return response.data;
};
