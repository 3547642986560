import { AccountResponse } from "src/apis/accounts";
import { requestAPI } from "src/apis/requestApi";
import {
  CustodyRequestControllerApiFactory,
  CustodyRequestStatus,
  DeleteOrganizationRequest,
  DetailedWalletCustodyRequestDto,
  DetailedWithdrawalCustodyRequestDto,
  PaginationWalletCustodyRequestDto,
  PaginationWithdrawalCustodyRequestDto,
  QrCodeDto,
  SimpleBalanceDto,
  RegisterOrganizationRequest,
  UpdateOrganizationDetailRequest,
  UpdateOrganizationCustodyFeeRequest,
  UpdateOrganizationAmlStatusRequest,
} from "src/__generate__/api";
import { Pageable } from "src/apis/interface";
import {
  ALL_CUSTODY_REQUEST_REGISTER_WALLET_STATUSES,
  ALL_CUSTODY_REQUEST_TRANSFER_STATUSES,
} from "src/interfaces/request";
import { AxiosPromise } from "axios";

const custodyRequestControllerApiFactory = () =>
  CustodyRequestControllerApiFactory(undefined, "", requestAPI());

export type CustodyTransferType = "wallet" | "withdrawal";

export type Signing = {
  account: AccountResponse;
  logs: SigningLog[];
};

export type SigningLog = {
  type: "QR_CREATION" | "CONFIRMATION";
  createdAt: number;
};

export type Approval = {
  account: AccountResponse;
  type: "ALLOW";
  memo: string | null;
  authority: string;
  createdAt: number;
};

export const requestTransfers = async (request: {
  statuses: CustodyRequestStatus[];
  search?: string;
  pageable: Pageable;
}): Promise<PaginationWithdrawalCustodyRequestDto> => {
  const { statuses, search, pageable } = request;
  const { page, size } = pageable;
  const response =
    await custodyRequestControllerApiFactory().getWithdrawalCustodyRequests1(
      undefined,
      statuses,
      search,
      page,
      size,
    );
  return response.data;
};

export const requestTransferById = async (request: {
  requestId: string;
}): Promise<DetailedWithdrawalCustodyRequestDto> => {
  const { requestId } = request;

  const response =
    await custodyRequestControllerApiFactory().getWithdrawalCustodyRequest1(
      requestId,
    );
  return response.data;
};

const getRequestTransferStatusCountByStatuses = async (
  statuses: CustodyRequestStatus[],
) => {
  const response = await requestTransfers({
    statuses,
    pageable: {
      page: 0,
      size: 1,
    },
  });
  return response.pagination.totalCount;
};

export const getRequestTransferProcessCounts = async () => {
  const [
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  ] = await Promise.all([
    getRequestTransferStatusCountByStatuses(
      ALL_CUSTODY_REQUEST_TRANSFER_STATUSES,
    ),
    getRequestTransferStatusCountByStatuses([CustodyRequestStatus.Requested]),
    getRequestTransferStatusCountByStatuses([
      CustodyRequestStatus.PendingSigning,
    ]),
    getRequestTransferStatusCountByStatuses([
      CustodyRequestStatus.PendingApproval,
    ]),
    getRequestTransferStatusCountByStatuses([
      CustodyRequestStatus.PendingFinalApproval,
    ]),
  ]);

  return {
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  };
};

export const requestWallets = async (request: {
  statuses: CustodyRequestStatus[];
  pageable: Pageable;
}): Promise<PaginationWalletCustodyRequestDto> => {
  const { statuses, pageable } = request;
  const { page, size } = pageable;

  const response =
    await custodyRequestControllerApiFactory().getWalletCustodyRequests1(
      undefined,
      statuses,
      undefined,
      page,
      size,
    );
  return response.data;
};

export const requestWalletById = async (request: {
  requestId: string;
}): Promise<DetailedWalletCustodyRequestDto> => {
  const { requestId } = request;

  const response =
    await custodyRequestControllerApiFactory().getWalletCustodyRequest1(
      requestId,
    );
  return response.data;
};

const getRequestRegisterWalletStatusCountByStatuses = async (
  statuses: CustodyRequestStatus[],
) => {
  const response = await requestWallets({
    statuses,
    pageable: {
      page: 0,
      size: 1,
    },
  });
  return response.pagination.totalCount;
};

export const getRequestRegisterWalletProcessCounts = async () => {
  const [
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  ] = await Promise.all([
    getRequestRegisterWalletStatusCountByStatuses(
      ALL_CUSTODY_REQUEST_REGISTER_WALLET_STATUSES,
    ),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestStatus.Requested,
    ]),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestStatus.PendingSigning,
    ]),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestStatus.PendingApproval,
    ]),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestStatus.PendingFinalApproval,
    ]),
  ]);

  return {
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  };
};

export const createQRCodeForSigning = async (request: {
  type: CustodyTransferType;
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<QrCodeDto> => {
  const { type, requestId, passphrase, otpCode } = request;
  const byType: Record<CustodyTransferType, () => AxiosPromise<QrCodeDto>> = {
    wallet: () =>
      custodyRequestControllerApiFactory().createWalletSigning(requestId, {
        passphrase,
        otpCode,
      }),
    withdrawal: () =>
      custodyRequestControllerApiFactory().createWithdrawalSigning(requestId, {
        passphrase,
        otpCode,
      }),
  };
  const response = await byType[type]();
  return response.data;
};

export const recreateQRCodeForSigning = async (request: {
  type: CustodyTransferType;
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<QrCodeDto> => {
  const { type, requestId, passphrase, otpCode } = request;

  const byType: Record<CustodyTransferType, () => AxiosPromise<QrCodeDto>> = {
    wallet: () =>
      custodyRequestControllerApiFactory().recreateWalletSigning(requestId, {
        passphrase,
        otpCode,
      }),
    withdrawal: () =>
      custodyRequestControllerApiFactory().recreateWithdrawalSigning(
        requestId,
        {
          passphrase,
          otpCode,
        },
      ),
  };
  const response = await byType[type]();
  return response.data;
};

export const validateRequestSigning = async (request: {
  type: CustodyTransferType;
  requestId: string;
}): Promise<void> => {
  const { type, requestId } = request;

  const byType: Record<CustodyTransferType, () => AxiosPromise<void>> = {
    wallet: () =>
      custodyRequestControllerApiFactory().validateWalletRequestSigning1(
        requestId,
      ),
    withdrawal: () =>
      custodyRequestControllerApiFactory().validateWithdrawalRequestSigning1(
        requestId,
      ),
  };
  await byType[type]();
};

export const confirmQRCode = async (request: {
  type: CustodyTransferType;
  requestId: string;
  qrCodeData: string;
}): Promise<void> => {
  const { type, requestId, qrCodeData } = request;

  const byType: Record<CustodyTransferType, () => AxiosPromise<void>> = {
    wallet: () =>
      custodyRequestControllerApiFactory().confirmWalletSigning(requestId, {
        qrCodeData,
      }),
    withdrawal: () =>
      custodyRequestControllerApiFactory().confirmWithdrawalSigning(requestId, {
        qrCodeData,
      }),
  };
  await byType[type]();
};

export const approveTransfer = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWithdrawalCustodyRequestDto> => {
  const { requestId, passphrase, otpCode } = request;

  const response =
    await custodyRequestControllerApiFactory().approveWithdrawal1(requestId, {
      passphrase,
      otpCode,
    });
  return response.data;
};

export const approveWallet = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWalletCustodyRequestDto> => {
  const { requestId, passphrase, otpCode } = request;

  const response = await custodyRequestControllerApiFactory().approveWallet1(
    requestId,
    {
      passphrase,
      otpCode,
    },
  );
  return response.data;
};

export const finalApproveTransfer = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWithdrawalCustodyRequestDto> => {
  const { requestId, passphrase, otpCode } = request;

  const response =
    await custodyRequestControllerApiFactory().finalApproveWithdrawal1(
      requestId,
      {
        passphrase,
        otpCode,
      },
    );
  return response.data;
};

export const finalApproveWallet = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWalletCustodyRequestDto> => {
  const { requestId, passphrase, otpCode } = request;

  const response =
    await custodyRequestControllerApiFactory().finalApproveWallet1(requestId, {
      passphrase,
      otpCode,
    });
  return response.data;
};

export const getCustodyBalances = async (request: {
  date: string;
}): Promise<SimpleBalanceDto[]> => {
  const { date } = request;

  const response =
    await custodyRequestControllerApiFactory().getCustodyBalances1(date);
  return response.data;
};

export const getCustodyBalancesCsv = async (request: { date: string }) => {
  const { date } = request;

  const response =
    await custodyRequestControllerApiFactory().getCustodyBalancesCsv1(date);
  return response.data;
};

export const requestOrganizationDeletion = async (
  request: DeleteOrganizationRequest,
) => {
  const { otpCode, passphrase, orgId } = request;

  const response =
    await custodyRequestControllerApiFactory().requestOrganizationDeletion({
      otpCode,
      passphrase,
      orgId,
    });
  return response.data;
};

export const requestOrganizationRegistration = async (
  data: RegisterOrganizationRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestOrganizationRegistration(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const requestUpdateOrganizationDetail = async (
  data: UpdateOrganizationDetailRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestUpdateOrganizationDetail(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const requestUpdateOrganizationCustodyFee = async (
  data: UpdateOrganizationCustodyFeeRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestUpdateOrganizationCustodyFee(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const requestUpdateOrganizationAmlStatus = async (
  data: UpdateOrganizationAmlStatusRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestUpdateOrganizationAmlStatus(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};
