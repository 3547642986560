import {
  BalanceDto,
  Blockchain,
  ClientRequestApprovalPolicy,
  ClientWalletV2ControllerApiFactory,
  EstimatedFeeDto,
  WalletV2WithRequestDto,
} from "src/__generate__";
import { requestAPI } from "../requestApi";
import { AxiosPromise } from "axios";

const clientWalletV2ControllerApiFactory = () =>
  ClientWalletV2ControllerApiFactory(undefined, "", requestAPI());

export const getEstimatedFee = async (
  walletId: string,
): Promise<EstimatedFeeDto> => {
  const response =
    await clientWalletV2ControllerApiFactory().getEstimatedFee(walletId);
  return response.data;
};

export const getMasterWallets = async (): Promise<WalletV2WithRequestDto[]> => {
  const response = await clientWalletV2ControllerApiFactory().getWallets();
  return response.data;
};

export const getBalance = async (walletId: string): Promise<BalanceDto[]> => {
  const response =
    await clientWalletV2ControllerApiFactory().getWalletBalances1(walletId);
  return (await response).data;
};

export const getMasterWalletById = async ({
  id,
}: {
  id: string;
}): Promise<WalletV2WithRequestDto> => {
  const response = await clientWalletV2ControllerApiFactory().getWallet(id);
  return response.data;
};

export type UpdateWalletPolicyTypeV2 =
  | "transfer"
  | "address_book_creation"
  | "address_book_deletion";

export const updateWalletPolicy = async (request: {
  walletId: string;
  type: UpdateWalletPolicyTypeV2;
  passphrase: string;
  otpCode: string;
  policy: ClientRequestApprovalPolicy;
}): Promise<void> => {
  const { walletId, type, ...rest } = request;
  const byType: Record<UpdateWalletPolicyTypeV2, () => AxiosPromise<void>> = {
    transfer: () =>
      clientWalletV2ControllerApiFactory().updateTransferApprovalPolicy(
        walletId,
        rest,
      ),
    address_book_creation: () =>
      clientWalletV2ControllerApiFactory().updateAddressBookCreationApprovalPolicy(
        walletId,
        rest,
      ),
    address_book_deletion: () =>
      clientWalletV2ControllerApiFactory().updateAddressBookDeletionApprovalPolicy(
        walletId,
        rest,
      ),
  };
  await byType[type]();
};

export const changeWalletName = async (request: {
  walletId: string;
  name: string;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, name, passphrase, otpCode } = request;
  await clientWalletV2ControllerApiFactory().updateWalletName(walletId, {
    newName: name,
    passphrase,
    otpCode,
  });
};

export const walletInitialized = async (walletId: string): Promise<void> => {
  await clientWalletV2ControllerApiFactory().initializeWallet(walletId);
};

export const validateAddress = async (request: {
  address: string;
  blockchain: Blockchain;
}): Promise<void> => {
  const { address, blockchain } = request;
  await clientWalletV2ControllerApiFactory().validateAddress({
    address,
    blockchain,
  });
};
