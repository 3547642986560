import { requestAPI } from "src/apis/requestApi";
import {
  CoinControllerApiFactory,
  CreateCoinRequest,
} from "src/__generate__/api";

const coinControllerApi = () =>
  CoinControllerApiFactory(undefined, "", requestAPI());

export const getCoins = async () => {
  const response = await coinControllerApi().getCoins1();
  return response.data;
};

export const createCoin = async (request: CreateCoinRequest) => {
  const response = await coinControllerApi().createCoin(request);
  return response.data;
};
